import { createTheme } from '@mui/material/styles';
import defaultTheme from './default';
import secondaryTheme from './secondary';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import onboardingTheme from './onboarding';

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        caption1: true;
        caption2: true;
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ExtendedTypographyOptions extends TypographyOptions {
    caption1: React.CSSProperties;
    caption2: React.CSSProperties;
}

const overrides = {
    typography: {
        h1: {
            fontSize: '2.25rem',
            fontWeight: 700,
            lineHeight: '150%',
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 700,
            lineHeight: '150%',
        },
        h3: {
            fontSize: '1.875rem',
            fontWeight: 700,
            lineHeight: '150%',
        },
        h4: {
            fontSize: '1.625rem',
            fontWeight: 700,
            lineHeight: '150%',
        },
        h5: {
            fontSize: '1.375rem',
            fontWeight: 700,
            lineHeight: '150%',
        },
        h6: {
            fontSize: '1.125rem',
            fontWeight: 700,
            lineHeight: '160%',
        },
        subtitle1: {
            fontSize: '1rem',
            fontWeight: 600,
            lineHeight: '175%',
        },
        subtitle2: {
            fontSize: '0.875rem',
            fontWeight: 600,
            lineHeight: '157%',
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '150%',
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '143%',
        },
        caption1: {
            fontSize: '0.81rem',
            fontWeight: 400,
            lineHeight: '143%',
        },
        caption2: {
            fontSize: '0.68rem',
            fontWeight: 400,
            lineHeight: '143%',
        },
        overline: {
            // @ts-ignore
            textTransform: 'uppercase' as TextTransform,
            fontSize: '0.68rem',
            fontWeight: 700,
            lineHeight: '266%',
        },
        fontFamily: 'Work Sans',
        fontWeightMedium: 600,
    },
};

const themes = {
    default: createTheme({ ...defaultTheme, ...overrides }),
    secondary: createTheme({ ...secondaryTheme, ...overrides }),
    onboarding: createTheme({ ...onboardingTheme }),
};

export default themes;
